.button-52 {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 22px 10px;
    outline: 0;
    color: #eb0028;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    backdrop-filter: blur(30px);
    z-index: 1;
    border-radius: 30px;
    border: 2px solid #eb0028;
    background-color: transparent;
    transition: 0.3s;
    text-transform: uppercase;
    min-width: 170px;
    display: inline-block;
    text-align: center;
}

.button-52:hover {
    background-color: rgba(221, 35, 35, 0.9);
    color: #fff;
}

.button-52-light {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 22px 10px;
    outline: 0;
    color: #fff;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    backdrop-filter: blur(30px);
    z-index: 1;
    border-radius: 30px;
    border: 2px solid #fff;
    background-color: transparent;
    transition: 0.3s;
    text-transform: uppercase;
    min-width: 170px;
    display: inline-block;
    text-align: center;
}

.button-52-light:hover {
    background-color: #fff;
    color: rgba(221, 35, 35, 0.9);
}

.button-52-light.brown:hover {
    background-color: #fff;
    color: #a98e75;
}

.height-100 {
    min-height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-slide {
    background-size: cover;
    background-position: center;
}

.slide-gradient-red {
    position: relative;
}

.slide-gradient-red::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, #eb0028 0%, rgba(121, 9, 9, 0) 0%, #eb0028 100%);
    z-index: 0;
}

.slide-gradient-black {
    position: relative;
}

.slide-gradient-black::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,1) 100%);
    z-index: 0;
}

.slide-gradient-light {
    position: relative;
}

.slide-gradient-light::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, #000 0%, rgba(121, 9, 9, 0) 0%, #000 100%);
    z-index: 0;
}