@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html, body {
    background-color: #fff;
}

#app-container {
    min-height: calc(100vh - 180px);
    background-color: #fff;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
}

#app-container h1 {
    font-optical-sizing: auto;
    font-size: 28pt;
    font-style: normal;
    font-weight: 500;
    word-break: break-word;
}

#app-container p {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
}

#app-container p.lead, #app-container .lead {
    font-weight: 500;
    font-size: 14pt;
}

#app-container p.small, #app-container .small {
    font-size: 10pt;
    font-weight: 400;
}

@media only screen and (max-width: 860px) {
    #app-container h1 {
        font-optical-sizing: auto;
        font-size: 24pt;
        font-style: normal;
        font-weight: 600;
        word-break: break-word;
    }
}

#app-container a {
    text-decoration: none;
}

#app-container hr {
    width: 60px;
    margin-left: auto;
    margin-right: auto;
}

#app-container h5 {
    font-optical-sizing: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.5px;
}