#header {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 999999;
}

#header-navbar {
    min-height: 80px;
    text-transform: capitalize;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

#header-navbar .nav-link {
    text-decoration: none;
    text-transform: uppercase;
    color: #888;
    position: relative;
    display: block;
    text-align: center;
    margin: 0 18px;
    padding: 5px 3px;
    transition: 0.3s;
}

#header-navbar .nav-link:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 1px;
    background-color: #eb0028;
    left: 3px;
    right: 3px;
    transition: 0.3s;
}

#header-navbar .nav-link:hover {
    color: #eb0028;
    transition: 0.3s;
}

#header-navbar .nav-link:hover:after {
    width: calc(100% - 6px);
}

@media only screen and (max-width: 992px) {
    #header .nav-link {
        padding: 15px 3px;
    }
    #menu-button {
        position: absolute;
        top: 0;
        right: 0;
        border: 0 !important;
        box-shadow: none !important;
    }
    #header .mobile-logo {
        display: flex;
    }
    #header-navbar .nav-link:hover:after {
        width: 0;
    }
}

@media only screen and (max-width: 760px) {
    #header #navbarScroll.collapsing, #header #navbarScroll.show {
        min-height: calc(100vh - 85px);
        align-items: center;
        display: flex;
        box-sizing: border-box;
        padding-bottom: 60px;
    }
    #header {
        border-bottom: 1px solid #eee;
    }
    #header-navbar .nav-link {
        font-size: 18px;
    }
}

#header-navbar .nav-link.active {
    color: #eb0028;
}

#header span.button-52 {
    font-size: 12px;
    padding: 8px 14px 8px;
    min-width: 140px;
    border-width: 2px;
}